import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/animate.min.css";
import "../../assets/css/style.css";
import "../../assets/vendors/iconic-fonts/cryptocoins/cryptocoins-colors.css";
import "../../assets/vendors/iconic-fonts/cryptocoins/cryptocoins.css";
import "../../assets/vendors/iconic-fonts/flat-icons/flaticon.css";
import "../../assets/vendors/iconic-fonts/font-awesome/css/all.min.css";
import { setCurrentUser } from "../../redux/user/user.action";
import Routes from "../_Routes";
import { logout } from "../../redux/user/userSlice";
import { withRouter } from "react-router";
import { APIBaseURL } from "../../services/APIUrl";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import IdleTimer from "./IdleTimer";
import ReactGA from "react-ga";
ReactGA.initialize("G-R6JHW8GW6T");
const theme = createTheme({
  palette: {
    primary: {
      light: "#5773ff",
      main: "#5773ff",
      dark: "#5773ff",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    action: {
      light: "#333",
      main: "#333",
      dark: "#333",
      contrastText: "#333",
    },
  },
});
var timer;

// Higher-order component to check server status
function withServerStatusCheck(WrappedComponent) {
  return function ServerStatusCheck(props) {
    return (
      <div>
        <WrappedComponent {...props} />
      </div>
    );
  };
}

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_token_expired: true,
      isLoading: true,
      isTimeout: false,
    };
  }

  logout = () => {
    localStorage.clear();
    localStorage.removeItem("activeNavItem");
    this.props.logout();
  };

  componentDidMount() {
    let time = APIBaseURL === "http://devbusiness.kayana.co.uk/" ? 1800 : 3600;

    timer = new IdleTimer({
      timeout: time, //expire after 10 seconds
      onTimeout: () => {
        this.setState({ isTimeout: true }, () => {});

        if (this.state.isTimeout) {
          this.logout();

          if (window.location.pathname !== "/login") {
            if (window.location.pathname !== "/") {
              window.location.pathname = "/";
            }
          }

          if (window.location.pathname !== "/") {
            if (window.location.pathname !== "/login") {
              window.location.pathname = "/";
            }
          }
        }
      },

      onExpired: () => {
        this.setState({ isTimeout: true });
        if (this.state.isTimeout) {
          this.logout();
          if (window.location.pathname !== "/login") {
            if (window.location.pathname !== "/") {
              window.location.pathname = "/";
            }
          }

          if (window.location.pathname !== "/") {
            if (window.location.pathname !== "/login") {
              window.location.pathname = "/";
            }
          }
        }
      },
    });
    //  }
  }

  componentWillUnmount() {
    timer.cleanUp();
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Routes />
        <ToastContainer position="top-center" />
      </ThemeProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  logout,
});

export default withServerStatusCheck(
  connect(null, mapDispatchToProps)(withRouter((props) => <App {...props} />))
);
